import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::013098370618:role/RUM-Monitor-ap-southeast-1-013098370618-0645595063861-Unauth",
    identityPoolId: "ap-southeast-1:c24935db-caac-45f6-b9e3-255988aaa7a9",
    endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
    telemetries: ["errors","http"],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = 'aa415472-4836-4e83-bc93-6cf0f47461bf';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'ap-southeast-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
  
  window.awsRum = awsRum
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}